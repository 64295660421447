import React, { useContext, useState } from "react";
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom";
import firebase, { db } from "../utils/firebase.js";  // should we isolate logic in a seperate component ...? 
import { nanoid } from "nanoid";
import { Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material'
import theme from '../styles/theme'
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { formatDate } from '../utils/utility.js';
import AuthBox from "./auth-box";
import toast from "react-hot-toast";
import Messages from "../constants/app.js";


export default function ForkProject() {
    //includeSelectedOrLatest_watchable = true;  // not handled,  partially implemented and commented out
    //includeSelectedOrLatest_seleactable_proxy_and_veneer = false // idea: not handled, requires sending over takes
    const { state, dispatch } = useContext(AppContext);
    const story = state.stories.activeStory //state.story //state.stories.activeStory
    const takes = state.stories.activeTakeList
    const shots = state.stories.activeShotList
    const user = state.userData
    const isLoggedIn = Object.keys(state.userData).length != 0 ? true : false
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [mediaOption, setMediaOption] = useState("Selected Media");

    const loc = window.location;

    const handleOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleMediaChange = (event) => {
      setMediaOption(event.target.value);
    };

    const setActiveStory = (board) => {
        
        if(board.portal_id != story.portal_id) {
          const payload = {
            activeStory: board, 
          }
          if(user && user.ref && story.ref) {
              user.ref.update({ lastActiveStory: board.ref }).then(() => {
                dispatch({ type: "UPDATE_ACTIVE_STORY", payload: payload });
                history.push('/p/'+board.portal_id)
              })
                .catch((error) => {
                    console.error('Error writing activeStoryRef to user document in database: ', error)
                })            
                
          }
        }
      }

      function forkIt() {
        const userRef = state.userData.ref
        const storyRef = story.ref
        const ownerIsForking = state.app.userIsStoryOwner
        const created = new Date()
        const createdStamp = firebase.firestore.FieldValue.serverTimestamp()
        if (!userRef) {console.log("FORK RETURN NO USER"); return} else {console.log("FORK USER ", userRef.id)}
        if (!storyRef) {console.log("FORK RETURN NO STORY"); return} else {console.log("FORK STORY", storyRef.id)}
        const portalId = nanoid(12)
        const storyId = nanoid()
        const newStoryRef = userRef.collection('stories').doc(storyId)/*(ownerIsForking) ? 
        userRef.collection('stories').doc(storyId) : 
        userRef.collection('stories').doc();*/
        
        const newStory = {
            title: formatDate(created, 'title'),
            portal_id: portalId,
            created_at: createdStamp,
            parts: story.parts,
            description: story.description ? story.description : "",
            last_cut: null,
            ref: newStoryRef
        }
    
        if ("last_audio" in story) {
            newStory["last_audio"] = story.last_audio
        }
        // console.log("FORK PREV STORY ", story)
        // console.log("FORK NEW STORY ", newStory)
        const portalRef = db.collection('portals').doc(portalId)
        const newPortal = {
            creator_id: userRef.id,
            story_id: storyId,
            created_at: createdStamp
        };
    
        const batch = db.batch();
        batch.set(portalRef, newPortal)
        batch.set(newStoryRef, newStory)
    
        for (let part of story.parts) {
            part.order.forEach((shot_id) => {
                const this_shot = shots.filter((shot) => shot.id == shot_id)[0]
                const newShotRef = newStoryRef.collection('shots').doc(shot_id)
    
                let image = this_shot.image
                let last_take_stream_url = null
    
                if (this_shot.last_take_im) {
                    image = this_shot.last_take_im
                }
                // if (includeSelectedOrLatest_watchable && this_shot.last_take_stream_url) {
                //     last_take_stream_url = this_shot.last_take_stream_url
                // }
    
                if (this_shot.selected_take_id) {
                    const this_take_list = takes.filter((take) => take.id == this_shot.selected_take_id);
                    const this_take = this_take_list[0];
                    if (this_take_list) {
                        image = this_take.thumbnail;
                    }
                }
    
                let prompt = "";
                if (this_shot.prompt) {
                    prompt = this_shot.prompt;
                }
    
                if (this_shot.selected_take_id) {
                    const this_veneer = state.stories.activeVeneerLUT[this_shot.selected_take_id];
                    if (this_veneer) {
                        prompt = this_veneer.prompt;
                    }
                }
    
                const newShot = {id: shot_id, // the same shot id, but different story. could enable trackable rights and merging one day
                    header: this_shot.header,
                    image: image,
                    meta: "",
                    prompt: prompt,
                    description: this_shot.description,
                    last_take_id: (mediaOption === "No Media") ? null : this_shot.last_take_id || null,
                    selected_take_id: (mediaOption === "No Media") ? null : this_shot.selected_take_id || null,
                    last_take_im: (mediaOption === "No Media") ? null : this_shot.last_take_im || null,
                    last_take_stream_url: (mediaOption === "No Media") ? null : last_take_stream_url,
                    deleted_at: null
                };
                // here we explicity add the fields ... in the future consider itter over all items and set the 4 above to null.  
                if ("tgt_stream_url" in this_shot) {
                    newShot["tgt_stream_url"] = this_shot.tgt_stream_url;
                }
                if ("tgt_dur_ms" in this_shot) {
                    newShot["tgt_dur_ms"] = this_shot.tgt_dur_ms;
                }
                batch.set(newShotRef, newShot);
            });
        }
    
        if (mediaOption === "All Media") {
            for (let take of takes) {
              // Fallback to empty string if undefined & set empty ""
                const newTake = {
                    video_path: take.video_path || "", 
                    thumbnail: take.thumbnail || "", 
                    id: take.id,
                    shot_id: take.shot_id || "", 
                    uploaded_at: take.uploaded_at || "", 
                    uploader_id: take.uploader_id || "", 
                    proxyLUT: state.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                    deleted_at: null
                };
                const newTakeRef = newStoryRef.collection('takes').doc(take.id);
                batch.set(newTakeRef, newTake);
            }
        } else if (mediaOption === "Selected Media") {
            for (let shot of shots) {
                const selectedTakes = takes.filter(take => take.id === (shot.selected_take_id || shot.last_take_id));
                for (let take of selectedTakes) {
                    const newTake = {
                        video_path: take.video_path || "", 
                        thumbnail: take.thumbnail || "", 
                        id: take.id,
                        shot_id: take.shot_id || "", 
                        uploaded_at: take.uploaded_at || "", 
                        uploader_id: take.uploader_id || "",
                        proxyLUT: state.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                        deleted_at: null
                    };
                    const newTakeRef = newStoryRef.collection('takes').doc(take.id);
                    batch.set(newTakeRef, newTake);
                }
            }
        }
    
        setOpen(false);

        const forkPromise = batch.commit().then(() => {
          setActiveStory(newStory)
        }).catch((error) => {
          console.error('Error Forking: ', error)
        })

        toast.promise(forkPromise, {
          loading: Messages.loading.waitingForFork,
          success: Messages.success.projectForkedSuccessFully,
          error: Messages.error.projectForkFailed,
        })
    }

    const forkDialog =         <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fork Project</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Do you want to fork this project to make a separate copy?
          </DialogContentText>

          <RadioGroup value={mediaOption} onChange={handleMediaChange} sx={{ my: 3 }}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={'auto'}>
                      <FormControlLabel value="No Media" control={<Radio />} label="No Media" />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                      <FormControlLabel value="All Media" control={<Radio />} label="All Media" />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                      <FormControlLabel value="Selected Media" control={<Radio />} label="Selected Media" />
                  </Grid>
              </Grid>
          </RadioGroup>
        
                
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            No
          </Button>
          <Button variant="contained" onClick={forkIt} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    const loginDialog =  <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fork Project</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          <AuthBox version="login-modal" redirect={loc.pathname} authFunc={forkIt} 
            value_prop="Log in to fork a copy of this project." />
        </DialogContent>
      </Dialog>

    return (
        <>
        <Link href="#" onClick={handleOpen} sx={{ display:'block', marginLeft:'10px' }} title="Fork Project">
            <AltRouteIcon sx={{ color:theme.palette.primary.contrastText }} />
        </Link>
        { isLoggedIn ? forkDialog : loginDialog }
      </>
    );

    //batch.commit()  // not yet

}