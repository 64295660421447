import React, { useContext, useState, useEffect } from "react";

import { APP_ROUTES } from "../constants/routes";
import AppContext from "../utils/app-context";
import { Redirect, useParams } from "react-router-dom";
import Loading from "./loading";
import NewProjectSelector from "../components/new";
import { useLocation } from 'react-router-dom'

function DynamicWrapper() {
  const { state } = useContext(AppContext);
  const [storyData, setStoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { login } = useParams();

  useEffect(() => {
    if (state.stories.storyList !== null) {
      setStoryData(state.stories.storyList);
      console.dir("stories", state.stories)
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [state.stories.storyList]);

  if (isLoading) {
    return <Loading />;
  } else if(!isLoading) {
    if(location.search.indexOf('login') != -1) {
      if(storyData !== null && storyData.length > 2) {        
        return <Redirect to={APP_ROUTES.STORY_BOARDS} />;
      } else {
        return <NewProjectSelector />;
      }
    } else {
      if(location.pathname == APP_ROUTES.NEW) {
        return <NewProjectSelector />;
      } else {
        return <Redirect to={APP_ROUTES.STORY_BOARDS} />;
      } 
    }
  } else {
    return <Loading />;
  }
}

export default DynamicWrapper;
