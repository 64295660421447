// app-context.js
import React, { createContext, useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import firebase, { db, analytics } from "../utils/firebase.js";
import { AuthContext } from "../utils/auth-provider";
import { matchPath, useLocation} from "react-router";
import { nanoid } from "nanoid";


// import { storyConstructor, albumConstructor } from "../utils/data"

const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
const isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

// Initial app context state
// TODO: load from the database
const initialStory = {
        parts: [{title:"The Beginning",order:["id-4NNPGRgIT6CbzCXLNHerL"]},
                {title:"The Middle",order:["id-4NNPGRgIT6CbzCXLNHerM"]},
                {title:"The End",order:["id-4NNPGRgIT6CbzCXLNHerN"]}],
        title: null, 
        target_duration: 15,
        pace: 1.5,
        portal_id: null,
        num_shots: null,  
        created: null, 
        deleted_at: null, 
        timerStart:null,
        isCompetition:false, 
        shots: [{ header: "Goal",
            description: 'HERO wants X',
            meta: 'Establish character & context',
            image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb009a.jpg",
            id: "id-4NNPGRgIT6CbzCXLNHerL",
            deleted_at: null
          },
          { header: 'Challenge',
            description: 'Faces OBSTACLE',
            meta: 'Emotional arc before & after obstacle',
            image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb004a.jpg",
            id: "id-4NNPGRgIT6CbzCXLNHerM",
            deleted_at: null      
          },
          { header: "Outcome",
            description: 'This is what happened',
            meta: 'Reveal uncertainty',
            image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg",
            id: "id-4NNPGRgIT6CbzCXLNHerN",
            deleted_at: null
          }]
    }

const initialState = {
    story: initialStory,
    app: {
        isLoading: true,
        isMobile,
        alerts: {},
        modal: {},
        upload: null,
        importing: null,
        userIsStoryOwner: false,
        prioritizeLivePreview: true,
        previewVolume: 1, 
        portal: {
            id: null,
            requested_id: null,
            },
        contributedCount: 0,
        controls: {
            backSelectOpen: false,
            addFabOpen: false,
            flip: false,
            drawerIsOpen: false,
            storiesOpen: false,
            sharedStorysOpen: false,
            inspirationOpen: false,
        },
        cardPreviews: {},
        cardPrice: 2,
        settings: {
            promptsVisible: true,                   // impacts app & stored in firebase, can be set on a settings page. 
            communityCanVeneer: true,               // impacts app & stored in firebase, an be set on a settings page. 
            communityCanToggleLivePreview: true,    // impacts app & stored in firebase, an be set on a settings page. 
            communityCanViewSavedPreview: true,     // This lets community see high res color (and thus could download it)
            communityCanDownloadTakes: true,
            communityCanDeleteTakes: false,         // this does allow community members to remove from firebase for view, but does not delete files in the backend
            communityCanListenToTakes: true,        // 
            savedPreviewHasAudio: true, 
            proxyIsBlackAndWhite: false,
            ytImportIsBlackAndWhite: false,
            userCanUploadLargeFiles: true,
            userCanListenToTakes: true,       
            communityCanForkWithMedia: true,         // 
            communityCanShare: true,
            communityCanStarred:true,
            // communityCanSelectTakes: true,          // tested and never rolled out to prod: we disabled suppport of state.app.settings.communityCanSelectTakes - its effectively false till we manage the data model and can confirm on dev
            // communityShotDescriptionEdit: true,  // an idea:  not used yet
            // communityCanDownload: false,         // an idea:  not used yet --  pershot, this has deeper firebase permission settings
        },
    },
    userData: {},
    stories: {
        storyList: [],
        sharedStoryList: [],
        activeStoryRef: null,
        nextStoryMetadata: null,
        activeStory: {},
        activeShotList: [],
        activeTakeList: [], 
        activeVeneerLUT: {},
        albumSourceSet: new Set(),
        starredStories:[],
    },
    inspiration: {
        allbumlist: [],
        initialAlbumID: 'ebb61632-8c51-47b3-a2e2-8ccf4373bde4',
        activeAlbumRef: null,
        activeAlbum: {},
        activeAlbumCardList: []
    }
};

// AppContext is the high level data store context for the App
const AppContext = createContext(null);

export function AppProvider({ children }) {

    // Check to see if the user is authenticated before fetching data
    const { currentUser } = useContext(AuthContext);
    // Main reducer for App context
    const reducer = (draft, action) => {
        const storyRef = draft.stories.activeStory.ref
        const shotRef = ("payload" in action && "shot_id" in action.payload) ? storyRef.collection('shots').doc(action.payload.shot_id) : null
        const takeRef = ("payload" in action && "take_id" in action.payload && action.payload.take_id != null) ? storyRef.collection('takes').doc(action.payload.take_id) : null

        // const userRef = (action.type.search("USER") > -1) ? db.collection('users').doc(currentUser.uid) : 0
        switch (action.type) {
            /*case "TOGGLE_USER_CAN_LISTEN_TO_TAKES":
                draft.app.settings.userCanListenToTakes = !draft.app.settings.userCanListenToTakes;
                return draft;*/

            // toggle story star function
            case "TOGGLE_STORY_STAR": {
                const { currentUser } = useContext(AuthContext);
                if (!currentUser) {
                console.error("User is not authenticated");
                return draft; 
            }
                const userRef = db.collection('users').doc(currentUser.uid);
                const { isStarred, portal_id } = action.payload;
                const starredPortalRef = userRef.collection('starred_portals').doc(portal_id);

                if (isStarred) {
                    starredPortalRef.set({ portal_id: portal_id })
                    .then(() => {
                draft.stories.starredStories.push(portal_id); 
                console.log(`Portal ${portal_id} starred`);
                })
            .catch((error) => console.error("Error starring portal: ", error));
                    } else {
                starredPortalRef.delete()
                 .then(() => {
                draft.stories.starredStories = draft.stories.starredStories.filter(id => id !== portal_id); // Remove the story from the state
                console.log(`Portal ${portal_id} unstarred`);
                 })
                .catch((error) => console.error("Error unstarring portal: ", error));
                }
                return draft 
            }
            case "UPDATE_STORY_PARTS":
                //draft.story.parts = action.payload
                storyRef.update({"parts": action.payload})
                return draft;
            case "UPDATE_STORY_SHOTS":
                const part_index = action.payload.part_index
                // draft.story.shots = [...draft.story.shots, ...action.payload.shots]
                // draft.story.parts[part_index].order = action.payload.shots.map(shot => shot.id)

                // we have the option to remove previous shots, or tag them as deleted
                // we need to delete if we want the counter to be accurate ...
                draft.stories.activeStory.parts[part_index].order.forEach((shot_id) => {
                    storyRef.collection('shots').doc(shot_id).update(
                        {"deleted_at": firebase.firestore.FieldValue.serverTimestamp()}
                )})
                if (storyRef){
                    action.payload.shots.forEach((shot) => {
                        const shotRef = storyRef.collection('shots').doc(shot.id);
                        shotRef.set(shot)
                            .then(() => shotRef)
                        .catch((error) => {
                            console.log('Error creating new shot:', shotRef)
                        })
                    })
                    var new_parts = draft.stories.activeStory.parts
                    new_parts[part_index].order = action.payload.shots.map(shot => shot.id)
                    storyRef.update({"parts": new_parts})
                }
            
            case "UPDATE_STORY_TITLE":
                if(storyRef) {
                    storyRef.update({"title": action.payload.title})
                }
                return draft

            case "UPDATE_STORY_DESCRIPTION":
                if(storyRef) {
                    storyRef.update({"description": action.payload.description})
                }
                return draft

            case "START_STORY_TIMER":
                const timerStart = action.payload.timerStart

                if (storyRef){

                    storyRef.update({"timerStart": timerStart})
                }
                return draft

            case "TOGGLE_STORY_COMPETITION":
                const isCompetition = action.payload.isCompetition

                if (storyRef){
                    storyRef.update({"isCompetition": isCompetition})
                    storyRef.update({"timerStart": null})
                }
                return draft

            case "TOGGLE_VOLUME":
                const volume = action.payload.volume

                draft.app.previewVolume = volume

                return draft
            case "ADD_STORY_SHOT":
                const part_id = action.payload.part_id
                const index = action.payload.index
                const part_ind = draft.stories.activeStory.parts.map(part => part.id).indexOf(part_id)

                const shot = action.payload.shot
                //draft.story.shots = [...draft.story.shots, shot]
                // draft.story.parts[part_ind].order = [...draft.story.parts[part_ind].order, shot.id]

                //draft.stories.activeShotList = [...draft.stories.activeShotList, shot]
                //draft.stories.activeStory.parts[part_ind].order = [...draft.stories.activeStory.parts[part_ind].order, shot.id]

                // This allows shots to be created in the cloud right away. 
                if (storyRef){
                        const shotRef = storyRef.collection('shots').doc(shot.id);
                        shotRef.set(shot)
                            .then(() => shotRef)
                        .catch((error) => {
                            console.log('Error creating new shot:', shotRef)
                        })

                        //var new_shots = [...draft.stories.activeShotList, shot]
                        //storyRef.update({"shots": new_shots})

                        //var new_parts = draft.stories.activeStory.parts
                        //[part_ind].order = [...new_parts[part_ind].order, shot.id]
                        //storyRef.update({"parts": draft.stories.activeStory.parts})


                        var new_parts = draft.stories.activeStory.parts

                        if(typeof index == 'number') console.dir([...new_parts[part_ind].order.slice(0, index), ...[shot.id], ...new_parts[part_ind].order.slice(index, new_parts[part_ind].order.length)])

                        if(typeof index == 'number')
                            new_parts[part_ind].order = [...new_parts[part_ind].order.slice(0, index), ...[shot.id], ...new_parts[part_ind].order.slice(index, new_parts[part_ind].order.length)]
                        else 
                            new_parts[part_ind].order = [...new_parts[part_ind].order, shot.id]

                        storyRef.update({"parts": new_parts})

                    //var mergedUpdate = {};
                    //mergedUpdate["shots"] = new_shots
                    //mergedUpdate["parts"] = new_parts
                    //storyRef.update(mergedUpdate)
                }


                // I would expect the snapshot to update state below, so this is a wierd hack:
                //    draft.stories.activeShotList = draft.story.shots  /
                //hmm don't we want to "get" this from the backend ...

            
                // // This allows shots to be created in the cloud right away.  All UPDATEs should follow if this is used.
                //         This will become relevant if/when there is collaboration
                //         on the story template by multiple users.  
                // const storyRef = draft.stories.activeStory.ref
                // if (storyRef){
                //     const shotRef = storyRef.collection('shots').doc(shot.id);
                //     shotRef.set(shot)
                //         .then(() => shotRef)
                //     .catch((error) => {
                //         console.log('Error creating new shot:', shotRef)
                //     })
                // }
                // console.log("ADD_STORY_SHOT", shot.id)
                return draft;

            case "MOVE_STORY_SHOT":
                const source_part_id = action.payload.source_part_id
                const dest_part_id = action.payload.dest_part_id
                const moving_shot_id = action.payload.moving_shot_id
                const preceding_shot_id = action.payload.preceding_shot_id

                var new_parts = draft.stories.activeStory.parts

                const source_part_index = new_parts.map(part => part.id).indexOf(source_part_id) 
                if (source_part_index === -1) {
                    console.error("MOVE_STORY_SHOT non-existant part!", source_part_id)
                }

                const dest_part_index = new_parts.map(part => part.id).indexOf(dest_part_id) 
                if (dest_part_index === -1) {
                    console.error("MOVE_STORY_SHOT non-existant part!", dest_part_index)
                }

                const dest_shot_index = preceding_shot_id === "000" ? 0 : new_parts[dest_part_index].order.indexOf(preceding_shot_id) + 1
                // console.log("MOVE_STORY_SHOT dest_shot_index", dest_shot_index, preceding_shot_id)

                // first we remove the moving shot
                new_parts[source_part_index].order = new_parts[source_part_index].order.filter(id => id!==moving_shot_id) 

                // then we add its id to the destination part
                // new_parts[dest_part_index].order = [...new_parts[dest_part_index].order, moving_shot_id] // at the end
                new_parts[dest_part_index].order.splice(dest_shot_index, 0, moving_shot_id) // where it was dropped

                storyRef.update({"parts": new_parts})

                return draft;
            case "DELETE_STORY_SHOT":

                if (false) { // local representation
                    //  we don't know which part index it is in, so we itterate and filter all parts for that id
                    for (var i = 0; i < draft.story.parts.length; i++) {
                        draft.story.parts[i].order = draft.story.parts[i].order.filter(id => id!==action.payload.shot_id) 
                    }
                    // we could remove it from our list ... but we also need to remove it from the backend if we create on the fly
                    draft.story.shots.filter(shot => shot.id!==action.payload.shot_id) 
                }

                if (false) { // something old
                    // we could handle this by filtering out deleted shots
                    function LogAsDeletedIfIdMatches(shot, payload) {
                        if (shot.id === payload.shot_id) {
                            //console.log(shot)

                            shotRef.update({"aaa": "ccc"})
                        }
                    }
                    draft.story.shots.map(shot => LogAsDeletedIfIdMatches(shot,action.payload))
                }

                const delete_shot_batch = db.batch()

                // mark as deleted (currently only available as a delayed cleanup optimization)
                //const shotRef = storyRef.collection('shots').doc(action.payload.shot_id);
                delete_shot_batch.update(shotRef, {"deleted_at": firebase.firestore.FieldValue.serverTimestamp()})

                // remove the shot from the ordered list in the appropriate story part
                var new_parts = draft.stories.activeStory.parts
                for (var i = 0; i < new_parts.length; i++) {
                    new_parts[i].order = new_parts[i].order.filter(id => id!==action.payload.shot_id) 
                }

                //console.log("IN DELETION, new parts", new_parts)
                delete_shot_batch.update(storyRef, {"parts": new_parts})
                delete_shot_batch.commit()
                draft.stories.activeStory.parts = new_parts
                return draft

            case "DELETE_STORYBOARD":
                const { boardRef } = action.payload;
                //const delete_storyboard_batch = boardRef.set();
                //const storyboardRef = db.collection('portals').where('story_id', '==', portal_id)

                boardRef.update({ "deleted_at": firebase.firestore.FieldValue.serverTimestamp() });

                return draft;





            case "DELETE_TAKE":
                //takeRef.update({"deleted_at": firebase.firestore.FieldValue.serverTimestamp()})
                const delete_take_batch = db.batch()
                delete_take_batch.update(takeRef, {"deleted_at": firebase.firestore.FieldValue.serverTimestamp()})
                var shot_payload = {}
                if (action.payload.shot_selected_take_id == action.payload.take_id) {
                    shot_payload["selected_take_id"] = null
                    shot_payload["selected_take_stream_url"] = null
                    shot_payload["selected_take_im"] = null
                }
                if (action.payload.shot_last_take_id == action.payload.take_id) {
                    shot_payload["last_take_stream_url"] = null
                    shot_payload["last_take_im"] = null
                    shot_payload["last_take_id"] = null
                }
                if (Object.keys(shot_payload).length > 0) { 
                    delete_take_batch.update(shotRef, shot_payload)
                }

                delete_take_batch.commit()

                return draft

            case "UPDATE_STORY_SHOT_DESCRIPTION":
                // function replaceDescIfIdMatches(shot, payload) {
                //     if (shot.id === payload.shot_id) {shot.description = payload.description}
                // }
                // draft.story.shots.map(shot => replaceDescIfIdMatches(shot, action.payload))

                shotRef.update({"description": action.payload.description})

                return draft

            case "MOVE_STORY_SHOT":

                // action.payload.shot_id

                // console.log('Proposed move:', (action.payload.shot_id, action.payload.source_part_id, action.payload.dest_part_id, action.payload.preceding_shot_id))

                //  similar logic as onDragEnd()  goes here

                return draft

            // case "UPDATE_STORY_SHOT_TITLE":
            //     // function replaceTitleIfIdMatches(shot, payload) {
            //     //     if (shot.id === payload.shot_id) {shot.header = payload.text}
            //     // }
            //     // draft.story.shots.map(shot => replaceTitleIfIdMatches(shot,action.payload))
            //     shotRef.update({"header": action.payload.text})
            //     return draft

            case "UPDATE_STORY_SHOT_TITLE":
                const { shot_id, text } = action.payload;
            
                if (shot_id && text !== undefined) {
                    draft.story.shots.forEach(shot => {
                        if (shot.id === shot_id) {
                            shot.header = text;
                        }
                    });
            
                    shotRef.update({ header: text })
                }
                //         .then(() => {
                //             // console.log("Document successfully updated!");
                //         })
                //         .catch(error => {
                //             // console.error("Error updating document: ", error);
                //         });
                // } else {
                //     // console.error("Invalid shot_id or text:", shot_id, text);
                // }
            
                return draft
            
             
            
 

            case "UPDATE_STORY_SHOT_PROMPT":
                shotRef.update({"prompt": action.payload.prompt})
                return draft

            case "UPDATE_USER_CREDITS":
                const userRef = db.collection('users').doc(currentUser.uid);
                // note:  we don't have a listener in the app, so potential future cloud billing might be wiped out by next user puchase
                draft.userData.credits = action.payload.credits
                userRef.update({"credits": action.payload.credits, "updated_at": firebase.firestore.FieldValue.serverTimestamp()})
                return draft

            case "UPDATE_STORY_SHOT_IMAGE":
                // function replaceImageIfIdMatches(shot, payload) {
                //     if (shot.id === payload.shot_id) {shot.image = payload.image}
                // }
                // draft.story.shots.map(shot => replaceImageIfIdMatches(shot,action.payload))
                shotRef.update({"image": action.payload.image})

                return draft
            /*case "UPDATE_SHOT_SELECTED_TAKE_IM": // possibly depricate in favor of UPDATE_SHOT_SELECTED_TAKE_IM
                shotRef.update({"last_take_im": action.payload.image}) // this was working

                return draft
            case "UPDATE_SHOT_EMPTY_TAKE":
                    shotRef.update({"selected_take_id": null, "last_take_id": null, "last_take_im": null, "last_take_stream_url": null, "image": action.payload.image}) // proposed new
                    // shotRef.update({"selected_take_im": action.payload.image})  maybe we add this logic ... selected_take_id
    
                    return draft
            */
           case "UPDATE_SHOT_LAST_TAKE":
                shotRef.update({"last_take_id": action.payload.take_id, "last_take_im": action.payload.image, "last_take_stream_url": action.payload.stream_path}) // proposed new

                return draft
            case "UPDATE_SHOT_SELECTED_TAKE":
                shotRef.update({"selected_take_id": (action.payload.image) ? action.payload.take_id : null, "selected_take_im": action.payload.image, "selected_take_stream_url": action.payload.stream_path}) // proposed new
                // shotRef.update({"selected_take_im": action.payload.image})  maybe we add this logic ... selected_take_id
                return draft
            
            case "STORY_IMPORT":
                const importPayload = action.payload;
                const importStoryRef = action.payload.storyRef;
                if (importStoryRef) {
                    const uploader_id = (importPayload.currentUser) ? importPayload.currentUser.uid : "anon"
                    const vid = {video_path: importPayload.video_path, 
                        thumbnail: importPayload.thumbnail,
                        id: importPayload.id,
                        type: "storyboard", 
                        uploaded_at: firebase.firestore.FieldValue.serverTimestamp(),
                        //modified_at: Date.now(), 
                        uploader_id: uploader_id,
                        proxyLUT : draft.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                        deleted_at: null}; 
                
                    const vidRef = importStoryRef.collection('imports').doc(importPayload.id)
                    vidRef.set(vid)
                        .then(() => vidRef)
                    .catch((error) => {
                        console.log('Error importing scenes:', importStoryRef)
                    })
                    //console.log("ADD_TAKE_TO_SHOT  cloud ", payload.take_id)

                }
            
                return draft

            case "ADD_TAKE_TO_SHOT":
                // push take to the cloud under SHOT
                const payload = action.payload;
                
                //const activeStoryRef = draft.stories.activeStoryRef   // this gets cleared
                if (storyRef) {
                    const uploader_id = (currentUser) ? currentUser.uid : "anon"
                    const take = {video_path: payload.video_path, 
                        thumbnail: payload.thumbnail,
                        id: payload.take_id,
                        shot_id: payload.shot_id,
                        uploaded_at: firebase.firestore.FieldValue.serverTimestamp(),
                        //modified_at: Date.now(), 
                        uploader_id: uploader_id,
                        proxyLUT : draft.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                        deleted_at: null}; 
                    
                    
                        //dur_ms
                        //est_created_time - from filename? or
                        //  // payload.user_id  .. why are these undefined!?  vs e.g. ahbMAd47J1aWnEhDrq5PwjQSmBU2


                    // I get the sense that this may be the way to go for large projects, with GET instead of snapshot
                    // but having N listeners for each participant seems overkill. 
                    //    this code remains here so it could be reanble for testing at a future date
                    //    As of Sept 2021 - Mar 2022 (including okapi-webdev data), this was mirrored in both places.
                    //    This state is not properly maintained for subsequent changes, like thumbnail updates, etc.
                    //  In Mar 2022, including all of 10zebra data, this was depricated.
                    //
                    // const takeRefALT = storyRef.collection('shots').doc(payload.shot_id).collection('takes').doc(payload.take_id)
                    // takeRefALT.set(take)
                    //    .then(() => takeRefALT)
                    // .catch((error) => {
                    //   console.log('Error creating new take:', takeRefALT)
                    // })

                    // we are actually using these takes for rendering the active state in the app
                    const takeRef = storyRef.collection('takes').doc(payload.take_id)
                    takeRef.set(take)
                        .then(() => takeRef)
                    .catch((error) => {
                        console.log('Error creating new take:', takeRef)
                    })
                    //console.log("ADD_TAKE_TO_SHOT  cloud ", payload.take_id)

                } else {
                    console.log("WARNING! Cant add take to story", draft.stories.activeStory)
                }

                // function addTakeIfIdMatches(shot, payload) {
                //     if (shot.id === payload.shot_id) {
                //         shot.takes = shot.takes || [];
                //         shot.takes.push({video_path: payload.video_path, thumbnail: payload.thumbnail})
                //     }
                // }
                // draft.story.shots.map(shot => addTakeIfIdMatches(shot,action.payload))
                return draft

            case "ADD_IMAGE_TO_SHOT":
                // push image to the cloud, we will treat it as a video with 1 second log, plus some meta data
                const im_payload = action.payload;
                if (storyRef) {
                    const uploader_id = (currentUser) ? currentUser.uid : "anon"
                    const take = {image_path: im_payload.image_path, 
                        // video_path: payload.video_path,  // should we add an loading video until we replace it?
                        thumbnail: im_payload.thumbnail,
                        id: im_payload.take_id,
                        shot_id: im_payload.shot_id,
                        uploaded_at: firebase.firestore.FieldValue.serverTimestamp(),
                        //modified_at: Date.now(), 
                        uploader_id: uploader_id,
                        proxyLUT : draft.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                        deleted_at: null}; 
                    
                const takeRef = storyRef.collection('takes').doc(im_payload.take_id)
                takeRef.set(take)
                    .then(() => takeRef)
                .catch((error) => {
                    console.log('Error creating new take:', takeRef)
                })
                } else {
                    console.log("WARNING! Cant add image take to story", draft.stories.activeStory)
                }

                return draft
            case "MOVE_TAKE_TO_SHOT":

                const destShotRef = ("payload" in action && "dest_shot_id" in action.payload) ? storyRef.collection('shots').doc(action.payload.dest_shot_id) : null
                //const oldShotRef = ("payload" in action && "shot_id" in action.payload) ? storyRef.collection('shots').doc(action.payload.shot_id) : null
                const move_take_batch = db.batch()
                //console.dir(takeRef)
                
                move_take_batch.update(takeRef, {"shot_id": action.payload.dest_shot_id, "modified_at": firebase.firestore.FieldValue.serverTimestamp()})
                move_take_batch.update(destShotRef, {"last_take_id": action.payload.take_id, "last_take_im": action.payload.image, "last_take_stream_url": action.payload.stream_path})
// if dest shot has selected shot
                var shot_payload = { }
                if (action.payload.prev_selected_take_id == action.payload.take_id) {
                    shot_payload["selected_take_id"] = null
                    shot_payload["selected_take_stream_url"] = null
                    shot_payload["selected_take_im"] = null
                }
                if (action.payload.prev_last_take_id == action.payload.take_id) {
                    shot_payload["last_take_stream_url"] = null
                    shot_payload["last_take_im"] = null
                    shot_payload["last_take_id"] = null
                }
                //console.log(action.payload.take_id)
                //console.log(action.payload.take_id)
                //console.dir(shot_payload)
                //console.log('--++++remove shot')
                if (Object.keys(shot_payload).length > 0) { 
                    move_take_batch.update(shotRef, shot_payload)
                    //console.log(shotRef)
                    //console.dir(shot_payload)
                }

                move_take_batch.commit()

                return draft
            // case "UPDATE_NUM_SHOTS":
            //     draft.story.num_shots = action.payload.num_shots;
            //     return draft;
            case "RESET_STATE":
                draft = initialState;
                return draft;

            case "CLEAR_LAST_CUT":
                draft.stories.activeStory.last_cut = null
                // storyRef.update{}
                return draft;
            case "CLEAR_LAST_EXPORT":
                draft.stories.activeStory.last_export = null
                return draft;
            case "APP_LOADED":
                draft.app.isLoading = false;
                return draft;
            case "ADD_ALERT": // Add alert to the list of active alerts
                // type: success, info, warning, error
                // message: string
                draft.app.alerts[Date.now()] = action.payload.alert;
                return draft;
            case "CLEAR_ALERT":
                delete draft.app.alerts[action.payload.key];
                return draft;
            case "MODAL":  // Set open/closed draft of App dialogs
                draft.app.modal = action.payload;
                return draft;
            case "CONTROL":  // Top level draft of app controls
                draft.app.controls[action.payload.name] = action.payload.state;
                return draft;
            case "RESET_FLIP":  // Reset flip initiator
                draft.app.controls.flip = false;
                return draft;
            case "FLIP_SHOTS":  // Initiate card flip sequence
                draft.app.controls.flip = true;
                return draft;
            case "SET_PREVIEW_IMAGE":  // Insert the preview into draft.cardPreviews with the cardID as the key
                // args: cardID, src
                {
                    let preview = {
                        src: action.payload.src,
                        orientation: action.payload.orientation,
                    }
                    draft.app.cardPreviews[action.payload.cardID] = preview;
                    return draft;
                }
            case "CLEAR_PREVIEW_IMAGE":  // Delete the image keyed off the cardID
                // args: cardID
                delete draft.app.cardPreviews[action.payload.cardID]
                return draft;
            case "START_UPLOAD":  // Configure and open upload modal
                analytics.logEvent('START_UPLOAD'); 
                const upload = {
                    data: {
                        totalFiles: action.payload.totalFiles,
                        totalBytes: action.payload.totalBytes,
                        uploadCount: 0,
                        failedCount: 0,
                        failedNames: [],
                        fileBytes: {},
                    }
                }
                draft.app.upload = upload;
                return draft;
            case "FILE_UPLOAD_UPDATE": {
                if (draft.app.upload) {
                    draft.app.upload.data.fileBytes[action.payload.cardID] = action.payload.bytes;
                }
                return draft;
            }
            case "FILE_UPLOAD_SUCCESS":  // Register one successful file uploaded
                if (draft.app.upload) {
                    draft.app.upload.data.uploadCount++;
                    draft.app.contributedCount = draft.app.contributedCount + draft.app.upload.data.totalFiles;
                    analytics.logEvent('FILE_UPLOAD_SUCCESS'); // , { count: draft.app.contributedCount});
                    // TODO: Clean up cards for failed uploads and notify the user
                    // Even if they have a preview image, they need an original image to be valid
                    if (draft.app.upload.data.uploadCount + draft.app.upload.data.failedCount === draft.app.upload.data.totalFiles) {
                        draft.app.upload = null
                    }
                }
                return draft;
            case "FILE_UPLOAD_FAILURE":  // Register a failed file upload
                // args: fileName
                if (draft.app.upload) {
                    draft.app.alerts[Date.now()] = {
                        type: 'error',
                        message: `Upload failed for ${action.payload.fileName}`,
                    }
                    draft.app.upload.data.failedCount++;
                    draft.app.upload.data.failedNames.push(action.payload.fileName)
                    analytics.logEvent('FILE_UPLOAD_FAILURE') //, { filename: action.payload.fileName}); 

                    // Even if they have a preview image, they need an original image to be valid
                    if (draft.app.upload.data.uploadCount + draft.app.upload.data.failedCount === draft.app.upload.data.totalFiles) {
                        // TODO: Open file failure modal to show names and let the user confirm
                        draft.app.upload = null
                    }
                } else {
                    console.error('Attempt to register file upload failure but file upload modal is not open.')
                }
                return draft;
            case "START_IMPORT":
                analytics.logEvent('START_IMPORT'); 
                const importing = {  // could update to frames processed ... not really required. 
                    data: {
                        totalFrames: 100,
                        processedFrames: 10,
                    }
                }
                draft.app.importing = importing;   
                return draft;
            case "IMPORT_SUCCESS":  
                // this just ends alert for now
                if (draft.app.importing) {
                    draft.app.importing = null
                }
                return draft;
            case "UPDATE_PORTAL":
                draft.app.portal.id = action.payload.portal_id;
                return draft; 
            case "UPDATE_ACTIVE_SHOT":
                //draft.stories.activeShotID = action.payload.shot_id;
                return draft; 
            case "ADD_REQUESTED_PORTAL":
                draft.app.portal.requested_id = action.payload.portal_id;
                return draft; 
            case "CLEAR_REQUESTED_PORTAL":
                draft.app.portal.requested_id = null;
                return draft; 
            case "UPDATE_ACTIVE_STORY_REF":
                draft.stories.activeStoryRef = action.payload.activeStoryRef;
                return draft;
            case "CLEAR_ACTIVE_STORY_REF":
                draft.stories.activeStoryRef = null;
                draft.stories.activeStory = {}
                draft.stories.activeShotList = [];
                return draft;
            case "CLEAR_ACTIVE_ALBUM_REF":
                draft.inspiration.activeAlbumRef = null;
                draft.inspiration.activeAlbum = {}
                draft.inspiration.activeAlbumCardList = [];
                return draft;
            case "UPDATE_USER_DATA":
                draft.userData = action.payload.userData;
                return draft;
            case "UPDATE_STORY_LIST":
                draft.stories.storyList = action.payload.storyList;
                return draft;
            case "UPDATE_SHARED_STORY_LIST":
                draft.stories.sharedStoryList = action.payload.sharedStoryList;
                return draft;
            case "NEW_ACTIVE_STORY":
                draft.stories.nextStoryMetadata = action.payload.newStory
                //draft.stories.activeStory = action.payload.newStory;
                return draft;
            case "CLEAR_NEXT_STORY_METADATA":
                draft.stories.nextStoryMetadata = null
                return draft;
            case "UPDATE_ACTIVE_STORY":
                draft.stories.activeStory = action.payload.activeStory;
                draft.app.userIsStoryOwner = null
                return draft;
            case 'SET_USER_IS_STORY_OWNER':
                draft.app.userIsStoryOwner = action.payload.userIsStoryOwner;
                return draft;
            case "UPDATE_ACTIVE_SHOT_LIST":
                draft.stories.activeShotList = action.payload.activeShotList;
                // console.log("UPDATE_ACTIVE_SHOT_LIST", action.payload.activeShotList)
                return draft;
            case "UPDATE_ACTIVE_TAKE_LIST":
                draft.stories.activeTakeList = action.payload.activeTakeList;
                return draft;
            case "UPDATE_ACTIVE_VENEER_LUT":
                const VeneerLUT = {};
                action.payload.activeVeneerList.forEach((vn) => {
                    VeneerLUT[vn.ref.id] = vn
                })
                draft.stories.activeVeneerLUT = VeneerLUT
                return draft;
            case "UPDATE_STORY_SETTINGS":
                let new_settings = draft.app.settings
                for (const key in action.payload.settings) {
                    new_settings[key] = action.payload.settings[key]
                }
                draft.app.settings = new_settings
                return draft
            case "UPDATE_PRIORITIZE_LIVE_PREVIEW":
                draft.app.prioritizeLivePreview = action.payload.prioritizeLivePreview;
                return draft
            case "UPDATE_ALBUM_SOURCE_SET":
                const newSourceSet = new Set();
                action.payload.sourceSet.forEach((sourceID) => {
                    newSourceSet.add(sourceID)
                })
                draft.stories.albumSourceSet = newSourceSet;
                return draft;
            case "UPDATE_ACTIVE_ALBUM_SHOT_LIST":
                draft.inspiration.activeAlbumCardList = action.payload.activeAlbumCardList;
                return draft;
            case "UPDATE_ALBUM_LIST":
                draft.inspiration.albumList = action.payload.albumList;
                return draft;
            case "UPDATE_ACTIVE_ALBUM_REF":
                draft.inspiration.activeAlbumRef = action.payload.activeAlbumRef;
                return draft;
            case "UPDATE_ACTIVE_ALBUM":
                draft.inspiration.activeAlbum = action.payload.activeAlbum;
                return draft;
            case "SET_STARRED_STORIES": 
                draft.stories.starredStories = action.payload.starredStories;
                return draft;
            default:
                return draft;
        }
    };
    const [state, dispatch] = useImmerReducer(reducer, initialState);
   




    useEffect(() => {
        if (!currentUser) return;
    
        const unsubscribe = db.collection('users').doc(currentUser.uid)
            .collection('starred_portals')
            .onSnapshot(snapshot => {
                const starredStories = snapshot.docs.map(doc => doc.id);
                dispatch({ type: "SET_STARRED_STORIES", payload: { starredStories } });
            });
    
        return () => unsubscribe();
    }, [currentUser]);






    // RESET STATE
    // When the user changes, reset the state 
    useEffect(() => {
        if (!currentUser || !state.userData.id) {
            dispatch({ type: "RESET_STATE" })
            return
        }
        if (state.userData.id !== currentUser.uid) {
            dispatch({ type: "RESET_STATE" })
        }
    }, [dispatch, currentUser, state.userData.id]);


    // define some async functions that are used in multiple Effacts: initial user setup and new project creation

    useEffect(() => {
        if (!currentUser) return;

        const unsubscribe = db.collection('users').doc(currentUser.uid)
            .collection('starred_portals')
            .onSnapshot(snapshot => {
                const starredStories = snapshot.docs.map(doc => doc.id);
                dispatch({ type: "SET_STARRED_STORIES", payload: { starredStories } });
            });

        return () => unsubscribe();
    }, [currentUser]);

    const setupInitialStory = async (userRef) => {
        const storyRef = userRef.collection('stories').doc();
        const newStory = initialStory; // storyConstructor();

        await newStory.shots.forEach((shot, key) => {
            console.log("INITIALIZE init SHOT", shot.id)
            const shotRef = storyRef.collection('shots').doc(shot.id);
            shotRef.set(shot)
                .then(() => shotRef)
            .catch((error) => {
                console.log('Error creating new shot:', shotRef)
            })
        })

        return storyRef.set(newStory)
            .then(() => storyRef)
            .catch((error) => {
                console.log('Error creating new story for user:', userRef.id)
                return error
            })
    }

    const initializeActiveStoryRef = async (userRef, storyRef, portal) => {
        let activeStoryRef = storyRef;
        if (storyRef.length) {
            // Check if story exists 
            activeStoryRef = await storyRef.get().then((doc) => {
                if (doc.exists) {
                    return storyRef
                } else {
                    return null;
                }
            })
        }

        // If not, check if there are other stories to set it to and set it to the first one
        if (!activeStoryRef) {
            // The original activeStory no longer exists, so check fo any other stories
            activeStoryRef = await userRef
                .collection('stories')
                .limit(1)
                .get().then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        return querySnapshot.docs[0].ref
                    } else {
                        return null
                    }
                })
        }

        // If no stories, create one and set it
        if (!activeStoryRef) {
            await setupInitialStory(userRef)
                .then((newStoryRef) => activeStoryRef = newStoryRef)
                .catch((error) => {
                    console.log('Unable to initialize activeStoryRef. Creating new story failed.', error)
                })
        }

        if (activeStoryRef) {
            dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { activeStoryRef } });
            userRef.update({ lastActiveStory: activeStoryRef })
                .catch((error) => {
                    console.error('Error writing activeStoryRef to user document in database: ', error)
                })
        }  
    }

    // USER DATA
    // Listen for updates to userData and initialize active story
    // If no user data, set up new user and new story
    useEffect(() => {
        const setupNewUser = async (user) => {
            const userRef = db.collection('users').doc(user.uid);
            const storyRef = userRef.collection('stories').doc();
            const newStory = initialStory; // storyConstructor();
            const name = user.displayName || "";
            const newUser = {
                name: name,
                email: user.email,
                account: "free",
                credits: 250,
                lastActiveStory: storyRef,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                deleted_at: null,
            }
            const batch = db.batch()
            batch.set(userRef, newUser)
            batch.set(storyRef, newStory)
            batch.commit().catch((error) => {
                console.error('Error setting up new user: ', error)
            })

            await newStory.shots.forEach((shot, key) => {
                console.log("INITIALIZE SHOT (for new user)", shot.id)
                const shotRef = storyRef.collection('shots').doc(shot.id);
                shotRef.set(shot)
                    .then(() => shotRef)
                .catch((error) => {
                    console.log('Error creating new shot:', shotRef)
                })
            })
        }

        const loc = window.location;  //  note:  useParams *should* work here, but doesn't, so we parse the URL for portal id
        let path = matchPath(loc.pathname, {
            path: '/p/:id/:shotID', 
            exact: false, 
            string: false
        })
        if(!path) {
            path = matchPath(loc.pathname, {
                path: '/p/:id', 
                exact: false, 
                string: false
            })
        }

        const initializeFromPortal=  async (portal_id) => {
            console.log('would do portal', portal_id )
            const portalRef =  db.collection('portals').doc(portal_id) 
                const _portal = await portalRef.get().then((doc) => {
                    if (doc.exists && !doc.get("deleted_at")) {
                        // console.log("PORTAL data:", doc.data());  defeats security if we just log it out, lol
                        const user_id = doc.data().creator_id
                        const story_id = doc.data().story_id

                        const activeStoryRefX =  db.collection('users').doc(user_id).collection('stories').doc(story_id) // db.database().ref('portal/' + portal_id + '/story_path');
                        dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { activeStoryRefX } });

                        let unSubscribe = () => { };
                        //try {     
                                unSubscribe =
                                activeStoryRefX.onSnapshot(doc => {
                                    if (doc.exists && !doc.get("deleted_at")) {
                                        const activeStory = doc.data();
                                        activeStory.ref = doc.ref;
                                        dispatch({ type: "UPDATE_ACTIVE_STORY", payload: { activeStory } });
                                    } else {
                                        console.log("Story? No such document!");
                                        //dispatch({ type: "CLEAR_ACTIVE_STORY_REF" });
                                    }
                                });
                        // catch (error) {
                        //         console.log('Error querying data for portal defined Story: ', error)
                        //     } finally {
                        //         return () => unSubscribe();
                        //     }
                        // }

                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
        }

        let do_user_init = true
        let do_active_story_init = true
        let portal_id = "non-portal"
        let shot_id = null
        if (!state.app.portal.id) {
            // A null id will get initialized to the portal id in the URL
            if (path != null && path?.params?.id){
                portal_id = path.params.id //(loc.pathname.match('/p/*')[0] === '/p/') ? loc.pathname.substring(3) : "non-portal"     
                console.log("portal: " +portal_id)   
                do_user_init = false
                do_active_story_init = false
                dispatch({ type: "UPDATE_PORTAL", payload: { portal_id } });
                /*if(path?.params?.shotID) {
                    shot_id = path.params.shotID
                    console.log("shotID: "+shot_id)
                    dispatch({ type: "UPDATE_ACTIVE_SHOT", payload: { shot_id } });
                } */           
            } else {
                portal_id = "non-portal"
            }
        } else if(!Object.keys(state.userData).length) {
            portal_id = state.app.portal.id
            do_user_init = true
            do_active_story_init = false
        } else {
            console.log('PORTAL_ID @ init', state.app.portal)
            do_user_init = false
            do_active_story_init = false
            portal_id = state.app.portal.id
        }

        if (do_user_init) {
            if (!currentUser) {  return }
            
            let unSubscribe = () => { };
            try {
                const userRef = db.collection('users').doc(currentUser.uid);
                // console.log(userRef);
                // console.log("user")
                unSubscribe = userRef.onSnapshot(doc => {
                    if (doc.exists && !doc.get("deleted_at")) {
                        const userData = doc.data();
                        userData.ref = doc.ref;
                        userData.superuser = false;
                        if (userData.email === 'pmm3123@gmail.com') {
                            userData.superuser = true;
                        }
                        if (!userData.account) {
                            // old accounts are set to free and 100 credits
                            userData.account = "free";
                        } 
                        if (!userData.credits) {
                            userData.credits = 500;
                        }
                        if (do_active_story_init && !state.stories.activeStoryRef) {
                            initializeActiveStoryRef(userRef, userData.lastActiveStory, state.app.portal)
                        }
                        dispatch({ type: "UPDATE_USER_DATA", payload: { userData } });
                    } else {
                        setupNewUser(currentUser)
                    }

                })
            } catch (error) {
                console.log('Error subscribing to updates for user data: ', error)
            } finally {
                return () => unSubscribe();
            }
        }     
        else {
            initializeFromPortal(portal_id)
        }
        
    }, [dispatch, currentUser, state.stories.activeStoryRef, state.app.portal])


    // Make a new story out of next story
    // Next story is locally in the app.   It propagates out to the cloud and back. 
    useEffect(() => {
        if (state.stories.nextStoryMetadata) {
            const newStory = state.stories.nextStoryMetadata
            const userRef = db.collection('users').doc(currentUser.uid);
            const newStoryId = nanoid(20);
            const newStoryRef = userRef.collection('stories').doc(newStoryId);
            const batch = db.batch()
            newStory.shots.forEach((shot, key) => {
                    //console.log("INITIALIZE SHOT", shot.id)
                    batch.set(newStoryRef.collection('shots').doc(shot.id), shot)
            })

            // delete newStory['shots']  // keeping shots IN the story is vestigal, so we could consider removing it
            batch.update(userRef, {lastActiveStory: newStoryRef});
            batch.set(newStoryRef, newStory)
            const portalRef = db.collection('portals').doc(newStory.portal_id);
            const newPortal = {creator_id: currentUser.uid, 
                story_id: newStoryId,
                created_at: firebase.firestore.FieldValue.serverTimestamp(), 
                deleted_at: null
            }
            batch.set(portalRef, newPortal)

                    // return portalRef.set(newPortal)
                    // .then(() => portalRef)
                    // .catch((error) => {
                    //     console.log('Error creating new portal:', portal_id)
                    //     return error
                    //     })
            batch.commit().then(() => {
                initializeActiveStoryRef(userRef, newStoryRef, state.app.portal).then(() => {
                    dispatch({ type: "CLEAR_NEXT_STORY_METADATA"});
                }).then(() => {                  // todo:  maybe only call this on complete of a promise of the above async = initializeActiveStoryRef
                    window.location.reload(false);  // we refresh the page b/c portal starts would not update to the new story
                })
            }).catch((error) => {
                console.error('Error creating new story: ', error)
            })
        }
    }, [state.stories.nextStoryMetadata])


    // propate last_cut to cloud.  ONLY used for clearing after a save. 
    useEffect(() => {
        if (state.stories.activeStoryRef) {
            const activeStoryRef = state.stories.activeStoryRef

            const setStorysLastCut = async (last_cut) => {
                return await state.stories.activeStoryRef.update( {"last_cut": last_cut})
                //.then(() => {console.log("Story last_cut updated successfully.")})
                .catch((error) => {
                    console.log('Error updating last_cut:', last_cut)
                    return error
                    })
            }
            if (state.stories.activeStory.last_cut === null) {
                //console.log('EFFECT, LAST CUT:', state.stories.activeStory.last_cut)
                setStorysLastCut(state.stories.activeStory.last_cut)
            }
        }
    }, [state.stories.activeStory.last_cut])


    // propate last_export to cloud.  used for clearing after a save. 
    useEffect(() => {
        if (state.stories.activeStoryRef) {
            const activeStoryRef = state.stories.activeStoryRef

            const setStorysLastExport = async (last_export) => {
                return await state.stories.activeStoryRef.update( {"last_export": last_export})
                //.then(() => {console.log("Story last_export updated successfully.")})
                .catch((error) => {
                    console.log('Error updating last_export:', last_export)
                    return error
                    })
            }
            if (state.stories.activeStory.last_export === null) {
                console.log('EFFECT, LAST EXPORT:', state.stories.activeStory.last_export)
                setStorysLastExport(state.stories.activeStory.last_export)
            }
        }
    }, [state.stories.activeStory.last_export])


    // STORY LIST
    // Listen for updates to story list (based on currentUser)
    useEffect(() => {
        if (!currentUser) return
        let unSubscribe = () => { };
        try {
            const storiesRef = db.collection('users')
                .doc(currentUser.uid)
                .collection('stories')
                //.where("deleted_at", "!=", Object);
            unSubscribe = storiesRef.onSnapshot(querySnapshot => {
                const storyList = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists && (!doc.get("deleted_at") || doc.get("deleted_at") == null)) {
                        const data = doc.data();
                        const story = { ref: doc.ref, ...data }
                        storyList.push(story)
                    }
                })
                dispatch({ type: "UPDATE_STORY_LIST", payload: { storyList } });
            })
        } catch (error) {
            console.log('Error subscribing to updates for story list: ', error)
        } finally {
            return () => unSubscribe();
        }
    }, [dispatch, currentUser])

    // // SHARED STORY LIST
    // // Listen for updates to shared story list (based on currentUser)
    // useEffect(() => {
    //     if (!currentUser) return
    //     let unSubscribe = () => { };
    //     try {
    //         const userRef = db.collection('users').doc(currentUser.uid);
    //         const storiesRef = db.collectionGroup('stories')
    //             .where("sharedWith", "array-contains", userRef)
    //             .where("deleted_at", "==", null);
    //         unSubscribe = storiesRef.onSnapshot(querySnapshot => {
    //             const storyList = [];
    //             querySnapshot.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const data = doc.data();
    //                     const story = { ref: doc.ref, ...data }
    //                     storyList.push(story)
    //                 }
    //             })
    //             dispatch({ type: "UPDATE_SHARED_STORY_LIST", payload: { sharedStoryList: storyList } });
    //         })
    //     } catch (error) {
    //         console.log('Error subscribing to updates for shared story list: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }


    // }, [dispatch, currentUser])

    // // ALBUM LIST
    // // Listen for updates to album list (based on currentUser)
    // useEffect(() => {
    //     if (currentUser) {
    //         let unSubscribe = () => { };
    //         try {
    //             const albumsRef = db.collection('albums')
    //                 .where("status", "==", "display")
    //                 .where("deleted_at", "==", null);
    //             unSubscribe = albumsRef.onSnapshot(querySnapshot => {
    //                 const albumList = [];
    //                 querySnapshot.forEach((doc) => {
    //                     if (doc.exists) {
    //                         const data = doc.data();
    //                         const album = { ref: doc.ref, ...data }
    //                         albumList.push(album)
    //                     }
    //                 })
    //                 dispatch({ type: "UPDATE_ALBUM_LIST", payload: { albumList } });
    //             })
    //         } catch (error) {
    //             console.log('Error subscribing to updates for album list: ', error)
    //         } finally {
    //             return () => unSubscribe();
    //         }


    //     }
    // }, [dispatch, currentUser])

    // ACTIVE STORY
    // Listen for updates to activeStory (based on state.activeStoryRef)
    useEffect(() => {
        if (!currentUser || !state.stories.activeStoryRef) return
        let unSubscribe = () => { };
        try {
            const activeStoryRef = state.stories.activeStoryRef
            unSubscribe =
                activeStoryRef.onSnapshot(doc => {
                    if (doc.exists) {
                        var activeStory = doc.data();
                        activeStory.ref = doc.ref;

                        // for back compatibility < 0.3.21 to append an ID to each part if it does not have one
                        for (var i = 0; i < activeStory.parts.length; i++) {
                            if (!('id' in activeStory.parts[i])) {
                                activeStory.parts[i] = {...  activeStory.parts[i], ... {id: nanoid()}}
                            }
                        }

                        //console.log('parts_id', activeStory.parts)
                        dispatch({ type: "UPDATE_ACTIVE_STORY", payload: { activeStory } });
                    } else {
                        dispatch({ type: "CLEAR_ACTIVE_STORY_REF" });
                    }
                });
        } catch (error) {
            console.log('Error subscribing to updates for active story: ', error)
        } finally {
            return () => unSubscribe();
        }
    }, [state.stories.activeStoryRef, currentUser]);


    // ACTIVE STORY SETTINGS
    // Listen for updates to activeStory (based on state.activeStoryRef)
    useEffect(() => {
        if (!state.stories.activeStory.ref) return
        let unSubscribeSettings = () => { };
        
        try {
            const settingsRef = state.stories.activeStory.ref.collection('settings').doc('0')
            let did_dispatch = false;
            unSubscribeSettings =
                settingsRef.onSnapshot(doc => {
                    if (doc.exists) {
                        const settings = doc.data();
                        // settings.ref = doc.ref;
                        dispatch({ type: "UPDATE_STORY_SETTINGS", payload: { settings } });
                        did_dispatch = true
                    } else {
                        if (currentUser) {
                            const initSettings = initialState.app.settings;
                            console.log('MAKING SETTINGS FOR THIS STORY', initSettings)
                            dispatch({ type: "UPDATE_STORY_SETTINGS", payload: { "settings": initSettings } });
                            settingsRef.set(initSettings)
                        }
                    }
                })

        } catch (error) {
            const msg = error.message
            console.log('Error subscribing to updates for story settings: ', msg)
            if (msg.search("empty")> 1 ){
                console.log('EMPTY DATA for story settings: ', msg)
            }
            else {
                console.log('ANOTHER ERROR for story settings: ', error)
            }
        } finally {
            return () => unSubscribeSettings();
        }
    }, [state.stories.activeStory.ref]);


    // PORTAL
    // Listen for requests to make a new portal
    useEffect(() => {

            if (!currentUser || !state.stories.activeStoryRef){
                return               
            } 
            if (!state.app.portal.requested_id){
                console.log("no portal requested ID")
                return
            } 

            if (state.stories.activeStory.portal_id){
                console.log("WARNING:   Portal already exists, don't make another", state.stories.activeStory.portal_id)
                return

                //ToDO later: enable user request for portal URL
                // Q: If there is an existing portal for this story, should we delete it?
                //
            } 

            const portal_id = state.app.portal.requested_id
            const createPortal = async (portal_id) => {
                const portalRef = db.collection('portals').doc(portal_id);
                const newPortal = {creator_id: currentUser.uid, 
                    story_id: state.stories.activeStoryRef.id,
                    created_at: firebase.firestore.FieldValue.serverTimestamp(), 
                    deleted_at: null
                }

                return await function() {
                    const ref = portalRef.set(newPortal);
                    return ref;
                } .catch((error) => {
                    console.log('Error creating new portal:', portal_id)
                    return error
                })
            }

            const setStorysPortal = async (portal_id) => {
                return await function() { 
                    const story = state.stories.activeStoryRef.update( {"portal_id": portal_id});
                    console.log("Story updated successfully.");
                    return story;
                }
                .catch((error) => {
                    console.log('Error updating portal id:', portal_id)
                    return error
                    })
            }

            // ASIDE:  it would be nice if these two actions were atomic
            // maybe serverside logic would help
            createPortal(portal_id)
            setStorysPortal(portal_id)
            console.log("CREATED PORTAL", state.app.portal.requested_id)
            dispatch({ type: "CLEAR_REQUESTED_PORTAL" });


            //await createPortal(portal_id)

    }, [state.app.portal.requested_id]);

    // Listen for updates to STORY (based on change in PORTAL ID)
    useEffect(() => {
        // const portal = state.app.portal
        // if (!portal.id) {
        //     // A null id will get initialized to the portal id in the URL
        //     let portal_id = "non-portal"
        //     if (loc.pathname.match('/p/*')){
        //         portal_id = (loc.pathname.match('/p/*')[0] === '/p/') ? loc.pathname.substring(3) : "non-portal"        
        //         dispatch({ type: "UPDATE_PORTAL", payload: { portal_id } });
        //     } else {
        //         console.log("NONMATCH PORTAL")
        //     }
        // }
        // if (portal.id) {
        //     console.log("EFFECT PORTAL_ID", portal.id)
        //     // this logic could move to initializeActiveStoryRef

        //     const portalRef =  db.collection('portals').doc(portal.id) 
        //     const _portal = portalRef.get().then((doc) => {
        //         if (doc.exists) {
        //             console.log("PORTAL data:", doc.data());
        //             const user_id = doc.data().creator_id
        //             const story_id = doc.data().story_id

        //             const activeStoryRefX =  db.collection('users').doc(user_id).collection('stories').doc(story_id) // db.database().ref('portal/' + portal_id + '/story_path');
        //             dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { activeStoryRefX } });

        //             let unSubscribe = () => { };
        //             //try {     
        //                     unSubscribe =
        //                     activeStoryRefX.onSnapshot(doc => {
        //                         if (doc.exists && !doc.get("deleted_at")) {
        //                             const activeStory = doc.data();
        //                             activeStory.ref = doc.ref;
        //                             dispatch({ type: "UPDATE_ACTIVE_STORY", payload: { activeStory } });
        //                         } else {
        //                             console.log("Story? No such document!");
        //                             //dispatch({ type: "CLEAR_ACTIVE_STORY_REF" });
        //                         }
        //                     });
        //             // catch (error) {
        //             //         console.log('Error querying data for portal defined Story: ', error)
        //             //     } finally {
        //             //         return () => unSubscribe();
        //             //     }
        //             // }

        //         } else {
        //             // doc.data() will be undefined in this case
        //             console.log("No such document!");
        //         }
        //     }).catch((error) => {
        //         console.log("Error getting document:", error);
        //     });
        // }
    }, [dispatch, state.app.portal])


    // LAST ACTIVE STORY
    // Set lastActiveStory for the user
    useEffect(() => {
        if (!currentUser || !state.stories.activeStoryRef) return
        const userRef = db.collection('users').doc(currentUser.uid);
        userRef.update({ lastActiveStory: state.stories.activeStoryRef }).catch((error) => {
            console.log('Error setting lastActiveStory for user: ', userRef.id, error)
        })
    }, [dispatch, state.stories.activeStoryRef, currentUser]);


    useEffect(() => {
        if (state.app.userIsStoryOwner === null) {
            let userIsStoryOwner = false

            if (currentUser && state.stories.activeStory && state.stories.activeStory.ref) {
                // console.log('user currentUser', currentUser.uid)
                // console.log('user activeStory', state.stories.activeStory)

                const story_path_array = state.stories.activeStory.ref.path.split("/");
                //console.log('user story_path', story_path_array);
                const uind = story_path_array.findIndex((item) => item == "users") + 1;
                //console.log('activeStoryRefs owner', story_path_array[uind]);
                if (state.stories.activeStory && currentUser.uid == story_path_array[uind]) { 
                    userIsStoryOwner = true
                }
            }
            //console.log("userIsStoryOwner", userIsStoryOwner)
            dispatch({ type: "SET_USER_IS_STORY_OWNER",  payload: { userIsStoryOwner }}); 
        }
    }, [currentUser, state.stories.activeStoryRef, state.app.userIsStoryOwner])   // state.app.userIsStoryOwner,  , 


    // // ACTIVE CARD LIST is similar to ACTIVE SHOT LIST ... see below
    // // Listen for changes to activeShotList
    // useEffect(() => {
    //     if (!currentUser || !state.stories.activeStoryRef) return
    //     let unSubscribe = () => { };
    //     try {
    //         const activeStoryRef = state.stories.activeStoryRef
    //         const shotsRef = activeStoryRef
    //             .collection('cards')
    //             .where('deleted_at', '==', null)
    //             .where('display', '==', true)
    //             .orderBy('orientation', 'desc')
    //             .orderBy('created_at', 'asc')
    //         unSubscribe = shotsRef.onSnapshot(querySnapshot => {
    //             const activeShotList = [];
    //             const sourceSet = [];
    //             querySnapshot.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const card = doc.data();
    //                     card.ref = doc.ref;
    //                     activeShotList.push(card)
    //                     if (card.image_source_id) {
    //                         sourceSet.push(card.image_source_id)
    //                     }
    //                 }
    //             })
    //             dispatch({ type: "UPDATE_ACTIVE_SHOT_LIST", payload: { activeShotList } });
    //             dispatch({ type: "UPDATE_ALBUM_SOURCE_SET", payload: { sourceSet } });
    //             dispatch({ type: "APP_LOADED", payload: {} });
    //         })
    //     }
    //     catch (error) {
    //         console.log('Error querying data for activeShotList: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }
    // }, [dispatch, state.stories.activeStoryRef, currentUser]);

    // ACTIVE SHOT LIST
    // Listen for changes to activeShotList
    useEffect(() => {
         //if (!currentUser || !state.stories.activeStoryRef) { console.log("return"); return }
        /*if (!state.stories.activeStoryRef) {
            initializeFromPortal("non-portal")
            /*console.log(userRef)
            console.log(storyRef)
            console.log(portal)
            console.log("init")
            if(!userRef) userRef = null
            if(!storyRef) storyRef = null
            if(!portal) portal = null
            initializeActiveStoryRef(userRef, storyRef, portal)
        }*/
        if (!state.stories.activeStory) return

        let unSubscribe = () => { };
        try {
            const activeStoryRef = state.stories.activeStory.ref
            if(!activeStoryRef) return
            const storyRef = activeStoryRef
                .collection('shots')
                .where('deleted_at', '==', null)
                //.orderBy('created_at', 'asc')
            // const storyRef = activeStoryRef
            //     .collection('shots')
            //     .where('deleted_at', '==', null)
            //     .where('display', '==', true)
            //     .orderBy('created_at', 'asc')
            unSubscribe = storyRef.onSnapshot(querySnapshot => {
                const activeShotList = [];
                // const sourceSet = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        const shot = doc.data();
                        shot.ref = doc.ref;
                        activeShotList.push(shot)
                        //if (card.image_source_id) {
                        //    sourceSet.push(card.image_source_id)
                        //}
                    }
                })

                if (activeShotList.length == 1) {
                    // if a single shot is in this list, we append
                    // this supports a rapid local render
                    const longer_list = state.stories.activeShotList.concat(activeShotList)

                    // in case we keep getting a single value over and over, it should not grow
                    const unique_list = longer_list.filter((v, i, a) => a.indexOf(v) === i)
                    
                    dispatch({ type: "UPDATE_ACTIVE_SHOT_LIST", payload: { activeShotList:  unique_list} });
                } else {
                    // this
                    dispatch({ type: "UPDATE_ACTIVE_SHOT_LIST", payload: { activeShotList } });                    
                }
                dispatch({ type: "APP_LOADED", payload: {} });
                // dispatch({ type: "UPDATE_ALBUM_SOURCE_SET", payload: { sourceSet } });
                
            })
        }
        catch (error) {
            console.log('Error querying data for activeShotList: ', error)
        } finally {
            return () => unSubscribe();
        }
    //}, [dispatch, state.stories.activeStoryRef, currentUser]);
    }, [dispatch, state.stories.activeStory]);



    
    // // NUM SHOTS  -  just use shots.length 
    // // Listen for changes to activeStory
    // useEffect(() => {
    //     if (!state.stories.activeStory) return
    //     if (!state.stories.activeShotList) return

    //     // // it seems we should count up the length of the lists in ORDER.   but the fields dont exist yet!  maybe activeStory is a promose?
    //     // if ("parts" in state.stories.activeStory && "order" in state.stories.activeStory.parts) {
    //     //     const num_shots = state.stories.activeStory.parts.reduce((count, part) => count = count + part.order.length, 0) 
    //     // } else {
    //     //     console.log("WARNING:  shot count is FAILING!", state.stories.activeStory)
    //     // }

    //     const num_shots = state.stories.activeShotList.length   // instead we do this, which SHOULD work beacuse deleted_at is filetered out
    //     dispatch({ type: "UPDATE_NUM_SHOTS", payload: { num_shots: num_shots } });              
        

    // }, [dispatch, state.stories.activeShotList]);  //  state.stories.activeStory, 


    // ACTIVE TAKE LIST
    // Listen for changes to activeTakeList
    useEffect(() => {
        //if (!currentUser || !state.stories.activeStoryRef) return  // user dependency removed.
        if (!state.stories.activeStory) return
        if (!state.stories.activeStory.ref) return
        let unSubscribe = () => { };
        try {
            //console.log("try")
            const activeStoryRef = state.stories.activeStory.ref
            const storyRef = activeStoryRef
                .collection('takes')
                .where('deleted_at', '==', null)

            unSubscribe = storyRef.onSnapshot(querySnapshot => {
                const activeTakeList = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        const take = doc.data();
                        take.ref = doc.ref;
                        activeTakeList.push(take)
                    }
                })
                dispatch({ type: "UPDATE_ACTIVE_TAKE_LIST", payload: { activeTakeList } });
            })
        }
        catch (error) {
            console.log('Error querying data for activeTakeList: ', error)
        } finally {
            return () => unSubscribe();
        }
    }, [state.stories.activeStory.ref]);

    // ACTIVE VENEER LIST
    // Listen for changes to activeVeneerList, and update the LUT
    useEffect(() => {
        if (!state.stories.activeStory) return
        if (!state.stories.activeStory.ref) return
        let unSubscribe = () => { };
        try {
            const activeStoryRef = state.stories.activeStory.ref
            const storyRef = activeStoryRef
                .collection('veneer')
                // .where('deleted_at', '==', null)

            unSubscribe = storyRef.onSnapshot(querySnapshot => {
                const activeVeneerList = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        const vn = doc.data();
                        vn.ref = doc.ref;
                        activeVeneerList.push(vn)
                    }
                })
                dispatch({ type: "UPDATE_ACTIVE_VENEER_LUT", payload: { activeVeneerList } });
            })
        }
        catch (error) {
            console.log('Error querying data for activeVeneerList: ', error)
        } finally {
            return () => unSubscribe(); 
        }
    }, [state.stories.activeStory.ref]);


  useEffect(() => {
        let prioritizeLivePreview = true
        if (!state.app.userIsStoryOwner) {
          prioritizeLivePreview = !state.app.settings.communityCanViewSavedPreview
        }
        dispatch({ type: "UPDATE_PRIORITIZE_LIVE_PREVIEW", payload: { prioritizeLivePreview:  prioritizeLivePreview} });
    }, [state.app.userIsStoryOwner, state.app.settings.communityCanViewSavedPreview]);


    // // ACTIVE ALBUM
    // // Fetch data for activeAlbum (based on state.activeAlbumRef)
    // useEffect(() => {

    //     const setupDefaultAlbum = async () => {
    //         const albumRef = db.collection('albums').doc();
    //         const newAlbum = albumConstructor();
    //         return albumRef.set(newAlbum)
    //             .then(() => albumRef)
    //             .catch((error) => {
    //                 console.log('Error creating default album:')
    //                 return error
    //             })
    //     }

    //     const initializeActiveAlbumRef = async () => {
    //         let activeAlbumRef = ""
    //         if (state.inspiration.initialAlbumID) {
    //             activeAlbumRef = db.collection('albums').doc(state.inspiration.initialAlbumID);
    //             // Check if album exists 
    //             activeAlbumRef = await activeAlbumRef.get().then((doc) => {
    //                 if (doc.exists && !doc.get("deleted_at")) {
    //                     return activeAlbumRef
    //                 } else {
    //                     return null;
    //                 }
    //             })
    //         }

    //         // If the album does not exist, check if there are other albums to set it to and set it to the first one
    //         if (!activeAlbumRef) {
    //             // The original activeAlbum no longer exists, so check fo any other albums
    //             activeAlbumRef = await db.collection('albums')
    //                 .where("status", "==", "display")
    //                 .where("deleted_at", "==", null)
    //                 .limit(1)
    //                 .get().then((querySnapshot) => {
    //                     if (!querySnapshot.empty) {
    //                         return querySnapshot.docs[0].ref
    //                     } else {
    //                         return null
    //                     }
    //                 })
    //         }

    //         // If still no albums, create one and set it
    //         if (!activeAlbumRef) {
    //             activeAlbumRef = await setupDefaultAlbum()
    //                 .then((newAlbumRef) => newAlbumRef)
    //                 .catch((error) => {
    //                     console.log('Unable to initialize activeAlbumRef. Creating new album failed.', error)
    //                 })
    //         }

    //         if (activeAlbumRef) {
    //             dispatch({ type: "UPDATE_ACTIVE_ALBUM_REF", payload: { activeAlbumRef } });
    //         }

    //     }


    //     if (!currentUser) return
    //     if (!state.inspiration.activeAlbumRef) {
    //         try {
    //             initializeActiveAlbumRef();
    //         } catch (error) {
    //             console.log('Error initializing active album: ', error)
    //         }
    //         return
    //     }

    //     let unSubscribe = () => { };
    //     try {
    //         const activeAlbumRef = state.inspiration.activeAlbumRef
    //         unSubscribe = activeAlbumRef.onSnapshot(doc => {
    //             if (doc.exists) {
    //                 const activeAlbum = doc.data();
    //                 if (!activeAlbum.deleted_at) {
    //                     activeAlbum.ref = doc.ref;
    //                     dispatch({ type: "UPDATE_ACTIVE_ALBUM", payload: { activeAlbum } });
    //                 }
    //             }
    //         });
    //     } catch (error) {
    //         console.log('Error subscribing to active album updates: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }
    // }, [dispatch, state.inspiration.activeAlbumRef, state.inspiration.initialAlbumID, currentUser]);

    // // ACTIVE ALBUM SHOT LIST
    // // Listen for changes to activeAlbumCardList
    // useEffect(() => {
    //     if (!currentUser || !state.inspiration.activeAlbumRef) return
    //     const activeAlbumRef = state.inspiration.activeAlbumRef
    //     // const activeAlbumRef = db.collection('albums').doc(state.inspiration.activeAlbumRef.id)
    //     // if (typeof activeAlbumRef.collection !== 'function') return
    //     let unSubscribe = () => { };
    //     try {
    //         const shotsRef = activeAlbumRef
    //             .collection('cards')
    //             .where('deleted_at', '==', null)
    //             .where('display', '==', true)
    //             .orderBy('orientation', 'desc')
    //             .orderBy('created_at', 'asc')
    //         unSubscribe = shotsRef.onSnapshot(querySnapshot => {
    //             const cardList = [];
    //             querySnapshot.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const card = doc.data();
    //                     card.ref = doc.ref;
    //                     cardList.push(card)
    //                 }
    //             })
    //             dispatch({ type: "UPDATE_ACTIVE_ALBUM_SHOT_LIST", payload: { activeAlbumCardList: cardList } });
    //         })
    //     }
    //     catch (error) {
    //         console.log('Error querying data for activeAlbumCardList: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }
    // }, [currentUser, dispatch, state.inspiration.activeAlbumRef]);

    // TODO: preload images for story and inspiration? 
    
    
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            { children}
        </AppContext.Provider>
    );
}

export default AppContext;