import React, { useContext, useState, useEffect } from "react";
import ReactPlayer from "react-player"
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../utils/auth-provider";
import { useLocation } from "react-router-dom";
import { useDropzone } from 'react-dropzone'
import { Typography, Box, Icon, Link } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import AddIcon from '@mui/icons-material/Add';
import { imageUpload, videoUpload } from '../helpers/index'
import { nanoid } from "nanoid";
import { v4 as uuidv4 } from 'uuid';
import firebase from '../utils/firebase';
import assets from "../utils/assets"
import { useParams } from "react-router-dom";
import theme from "../styles/theme";
import ZebraPlayer from "./zebra-player";
import { SortableItem } from './SortableItem';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  MouseSensor, 
  PointerSensor,
  useSensor,
  useSensors,
  useDraggable,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import DropUpload from "./drop-upload";
import toast from "react-hot-toast";

function RecordableShotGroup(props) {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [showVid, setShowVid] = useState({});
  const [vidPlay, setVidPlay] = useState({});
  const [vidHover, setVidHover] = useState({});
  const [playShot, setPlayShot] = useState({});
  const [isDragOver, setIsDragOver] = useState(false);
  const [shotsCount, setShotsCount] = useState(0);

  const { id } = useParams();
  const next_shot_index = props.shots.length + 1
  const active_story = state.stories.activeStory

  const portal_id = props.portal // ? props.portal : (state.stories.activeStory.portal_id) ? state.stories.activeStory.portal_id : state.app.portal.id

  const [expanded, setExpand] = useState(true);
  const [isSelected, setIsSelected] = useState(true);

  const story = state.stories.activeStory;
  let localId = null
  async function addShot(part_id, description) {

    const random_int = Math.floor((Math.random() * 18))
    const biased_int = [0, 0, 1, 1, 1, 1, 2, 2, 3, 3, 5, 5, 6, 6, 7, 7, 8, 8, 0][random_int]
    const shot_type_filenames = ["wide.png", "mid.png", "action.png", "close.png", "empty.png", "xcu.png", "two_shot.png", "ots.png", "full.png", "action_close.png",]
    const shot_type = shot_type_filenames[biased_int]
    const random_image = `https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/${shot_type}`

    //const order = story.parts[part_index].order
    //const prev_image = (order.length > 0) ? shots.filter(shot => shot.id===order[order.length - 1])[0].image: " "
    //var prev_meta = (order.length > 0) ? shots.filter(shot => shot.id===order[order.length - 1])[0].meta: " "
    //if (prev_meta === undefined) {
    //  prev_meta = ""
    //}   

    const new_panel_desc = ['Wide shot', 'Mid shot', 'Action shot', 'Close up of face', '', 'Extreme close up', 'Two-Shot', 'Over the shoulder', 'Full shot', 'Action Close up'][biased_int]
    const one_word_title = description.substring(description.lastIndexOf(" ") + 1)
    const punctuation_free_title = one_word_title.replace(/[".,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
    const capitalized_title = punctuation_free_title[0].toUpperCase() + punctuation_free_title.substring(1);
    localId = nanoid()
    const newShot = {
      id: localId,
      header: capitalized_title,
      image: random_image,
      meta: "",
      prompt: "",
      description: new_panel_desc,
      last_take_id: null,
      selected_take_id: null,
      last_take_im: null,
      last_take_stream_url: null,
      deleted_at: null
    };

    const payload = {
      part_id: part_id,
      shot: newShot
    }
    dispatch({ type: "ADD_STORY_SHOT", payload: payload });
  }

  const uploadBlob = (blob, dispatch, path) => {
    const storageRef = firebase.storage().ref();

    dispatch({ type: "START_UPLOAD", payload: { totalBytes: blob.size, totalFiles: 1 } });

    let uploadResolve, uploadReject;
    const uploadPromise = new Promise((resolve, reject) => {
      uploadResolve = resolve;
      uploadReject = reject;
    });

    const blobRef = storageRef.child(path)

    const uploadNext = (snapshot) => {
      // const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      const bytes = snapshot.bytesTransferred;
      dispatch({ type: "FILE_UPLOAD_UPDATE", payload: { path, bytes } });
    }

    const uploadError = (error) => {
      console.log(`Error uploading blob`, error)
      uploadReject(error)
    }

    const uploadComplete = () => {
      dispatch({ type: "FILE_UPLOAD_SUCCESS", payload: {} });
      uploadResolve('upload completed');
    }

    const blobUploadTask = blobRef.put(blob);

    blobUploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
      'next': uploadNext,
      'error': uploadError,
      'complete': uploadComplete
    });

    return uploadPromise;
  }

  async function onFileUploadChange(shot_id, event) {

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file) {
        const file_MB = file.size / 1000000
        const max_MB = 25
        if (file_MB > max_MB) {
          alert(`Please use shorter video clips. ${file_MB.toPrecision(3)}MB is over the ${max_MB}MB limit.`)
        } else {
          console.log("onFileUploadChange ... " + shot_id + " " + file_MB.toPrecision(3) + "MB")

          const take_id = uuidv4();
          const storageDir = "media"
          //const path = `${storageDir}/${filename}.mp4`

          let oid = "unknown"
          let sid = "unknown"
          if (state.stories.activeStory && state.stories.activeStory.ref) {
            // const story_path = state.stories.activeStory.ref.u_.path 
            // const OID_ind = story_path.segments.findIndex((item) => item == "users") + 1 

            const story_path_array = state.stories.activeStory.ref.path.split("/");

            //console.log("story_path", story_path_array)
            const OID_ind = story_path_array.findIndex((item) => item == "users") + 1
            //console.log("OID_ind", OID_in dd)
            oid = story_path_array[OID_ind]
            //console.log("OID", oid)

            const SID_ind = story_path_array.findIndex((item) => item == "stories") + 1
            //console.log("SID_ind", SID_ind)
            sid = story_path_array[SID_ind]
            //console.log("SID", sid)
            alert(SID_ind)
            alert(OID_ind)
          }

          const uploader_id = currentUser ? currentUser.uid : "unknown"
          const path = `${storageDir}/us/${oid}/st/${sid}/up/${uploader_id}/ta/${uuidv4()}.mp4`
          const uploadPromise = uploadBlob(file, dispatch, path)
          let result = await uploadPromise;

          if (result === 'upload completed') {
            const payload = {
              video_path: path,
              thumbnail: assets['processing_thumb'],
              shot_id: shot_id,
              take_id: take_id,
            }
            dispatch({ type: "ADD_TAKE_TO_SHOT", payload: payload });
          }
        }
      } else {
        console.log("onFileUploadChange ... empty file ... canceled event")
      }
    }
  }

  function onClickedShot(shot) {
    // props.setSelectedShot(shot)
  }

  function expand() {
    setExpand(true)
  }

  function collapse() {
    setExpand(false)
  }

  const sortProps = { ...props }


  const stopPlaying = (id) => {
    setPlayShot({ ...playShot, [id]: false })
    setVidHover({ ...vidHover, [id]: false })
  }

  const playerInit = (shot) => {
    setShowVid({ ...showVid, [shot.id]: true })
    setVidHover({ ...vidHover, [shot.id]: true })
    setPlayShot({ ...playShot, [shot.id]: true })
  };

  const handlePlayer = (shot) => {
    if (shot.last_take_stream_url) {
      playerInit(shot)
    }
  }
  const handlePlay = (id) => {
    if (!vidPlay[id]) setVidPlay({ ...vidPlay, [id]: 'video-play' })
  }

  const location = useLocation();

  useEffect(() => {
    setShowVid({})
    setPlayShot({})
    setVidPlay({})
    setVidHover({})
  }, [location]);

  let shots = props.shots.filter(s => s !== undefined)

  if (Object.keys(showVid).length === 0 && shots.length) {
    let shotObj = {}
    for (let shot of shots) {
      shotObj[shot.id] = false;
    }
    setShowVid(shotObj)
    setPlayShot(shotObj)
    setVidPlay(shotObj)
    setVidHover(shotObj)
  }

  function repeatString(string, n) {
    let repeatedString = "";
    while (n > 0) {
      repeatedString += string;
      n--;
    }
    return repeatedString;
  }

  const gridItemStyles = {
    width: '100%',
    height: '100%',
    touchAction:"manipulation", 
    '.react_player': { aspectRatio: '16 / 9', height: 'auto !important' },
  }

  const addItemStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px !important',
  }

  const goTake = (id) => {
    history.push('/p/' + portal_id + '/' + id)
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: '10z-shots',
  });

  const titleStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
    justifyContent: 'space-between',
    'h2': {
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      'h2': {
        lineHeight: '28px'
      }
    }
  }



  const handleDragEnd = (event) => {
    const { active, over } = event;

    const activeContainer = event.active.data.current.sortable.containerId;
    const overContainer = event.over.data.current.sortable.containerId;

    const activeIndex = event.active.data.current.sortable.index;
    const overIndex = event.over.data.current.sortable.index;

    const source_part_id = story.parts[activeContainer].id
    const dest_part_id = story.parts[overContainer].id
    const moving_shot_id = active.id  // columns[activeContainer].items[source.index].id /// columns[activeContainer].items[oldIndex].id
    let preceding_shot_id = '000'

    if (overIndex > 0) {
      const items = story.parts[overContainer].order.map(shot_id => shots.filter(shot => shot.id === shot_id)[0]).filter(s => s !== undefined)
      preceding_shot_id = items[overIndex - 1].id
    }

    if ((activeIndex !== overIndex) || (activeContainer !== overContainer)) {
      const payload = { source_part_id: source_part_id, dest_part_id: dest_part_id, moving_shot_id: moving_shot_id, preceding_shot_id: preceding_shot_id }
      dispatch({ type: "MOVE_STORY_SHOT", payload: payload });
    }
    if (false) {
      if (active.id !== over.id) {
        const oldIndex = columns[activeContainer].items.findIndex(({ id }) => id === active.id);
        const newIndex = columns[overContainer].items.findIndex(({ id }) => id === over.id);
        const result = arrayMove(columns[overContainer].items, oldIndex, newIndex);

        //console.log('oldindex', columns[activeContainer].items)
        //console.log('newindex', result)
        if (activeContainer !== overContainer) {
          const sourceColumn = columns[activeContainer];
          const destColumn = columns[overContainer];
          const sourceItems = [...columns[activeContainer].items];
          const destItems = [...columns[overContainer].items];
          //console.log('destColumn', sourceColumn)
          //console.log('destColumn', destColumn);
          //console.log('sourceItems',sourceItems);
          //console.log('destItems', destItems);
          const [removed] = sourceItems.splice(oldIndex, 1);
          destItems.splice(newIndex, 0, removed)

          // setColumns({
          //   ...columns, 
          //   [activeContainer]: {
          //     ...sourceColumn,
          //     items: sourceItems
          //   },
          //   [overContainer] : {
          //     ...destColumn,
          //     items: destItems
          //   }

          // })

          return true
        }

        // setColumns(columns => ({
        //   ...columns,
        //   [activeContainer]: {
        //     ...columns[activeContainer],
        //     items: result
        //   }
        // }))

        return true
      }
    }
  }

  const shot_group =
    <Box>
      <Grid container spacing={{ xs: 2 }}>
        {!state.app.isLoading && shots.map((shot, key) => (
          <Grid xs={6} sm={4} md={3} lg={2} key={shot.id + "card"}>
            <Item>
              <Link sx={{ textDecoration: 'none', '&:hover': { cursor: 'pointer' } }} onClick={(e) => { goTake(shot.id) }}>
                <Box className={`video-wrapper ${vidPlay[shot.id]}`} /* className="image-file-upload" */ onMouseOver={() => { handlePlayer(shot) }} onMouseOut={() => setVidHover({ ...vidHover, [shot.id]: false })} htmlFor={shot.id + "file-capture"}>
                  {(props.viewLatestTake && (!shot.selected_take_im && !shot.last_take_im)) && <Box component="img" sx={{ width: '100%', height: 'auto', display: 'block' }} src={shot.image} fluid="true" />}
                  {(props.viewLatestTake && (shot.selected_take_im || shot.last_take_im)) && <Box component="img" sx={{ width: '100%', height: 'auto', display: 'block' }} src={shot.selected_take_im || shot.last_take_im} fluid="true" />}
                  {!props.viewLatestTake && <Box component="img" sx={{ width: '100%', height: 'auto', display: 'block' }} src={shot.image} fluid="true" />}
                  {<Box sx={{ zIndex: 9, padding: '.05em .5em', backgroundColor: '#272727', position: 'absolute', left: '5px', bottom: '5px', '&:hover': { backgroundColor: '#B4B4B4', transition: 'all 150ms', 'a': { transition: 'all 150ms', cursor: 'pointer', color: '#000' } } }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#fff', fontSize: '.7em', lineHeight: '2em', textDecoration: 'none' }}><Box component="span">{(props.takes.filter(take => take.shot_id == shot.id).length == undefined || props.takes.filter(take => take.shot_id == shot.id).length == 0) ? '+' : props.takes.filter(take => take.shot_id == shot.id).length}</Box></Box>
                  </Box>}
                  {showVid[shots[key].id] && <Box className={'video'}><ZebraPlayer sx={{ width: '100%', height: '100%' }} video_urls={[shot.selected_take_stream_url || shot.last_take_stream_url]} controls={false} playing={playShot[shot.id]} onPlay={() => { handlePlay(shot.id) }} onEnded={() => { stopPlaying(shot.id) }} loop={vidHover[shot.id]} /></Box>}

                </Box>

                {/*<input id={shot.id + "file-capture"} type="file" onChange={(e) => onFileUploadChange(shot.id, e)} accept="video/*" capture="environment"/>*/}

                <Box sx={{ padding: '15px' }}>
                  {/*props.displayImages && <br />*/}

                  <Typography variant="h3" sx={{ margin: '0 0 5px' }}>{shot.header}</Typography>
                  <Typography variant="body1"> {shot.description} </Typography>
                </Box>

                {/*{takes_per_shot[shot.id] && <Card.Content extra>
                {takes_per_shot[shot.id] && takes_per_shot[shot.id]<=10 && <a onClick={() => see_takes(shot)}> <Icon name='video' /> {repeatString('|',takes_per_shot[shot.id])}  </a>}
                {takes_per_shot[shot.id] && takes_per_shot[shot.id]>10 && <a onClick={() => see_takes(shot)}> <Icon name='video' /> {takes_per_shot[shot.id]} </a>}
                {false && !currentUser && <a onClick={() => {alert("Login required to review existing takes.")}}>  <Icon name='video' /> {takes_per_shot[shot.id]} { takes_per_shot[shot.id]==1 ? 'take' :'takes' } </a>}
              
              </Card.Content>}*/}



              </Link></Item></Grid>
        ))}
      </Grid>
    </Box>

  const card_group =
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Grid
        container spacing={{ xs: 2 }}>
        {Object.entries(story.parts).map(([key, part]) => (
          <SortableContext
            key={key}
            items={part.order.map(shot_id => shots.filter(shot => shot.id == shot_id)[0]).filter(s => s !== undefined)}
            strategy={rectSortingStrategy}
            id={key}
          >

            {!state.app.isLoading && part.order.map(shot_id => shots.filter(shot => shot.id == shot_id)[0]).filter(s => s !== undefined).map((item, key) => (
              <SortableItem part_id={props.part_id} next_shot_index={props.shots.length + 1} key={key} shot={item} setSelected={() => { setIsSelected(item.id); props.setMoveToShot(item.id) }} {...sortProps} selected={isSelected == item.id} sx={gridItemStyles} /*onPlayChange={ handlePlay }*/ />
            ))}
          </SortableContext>
        ))}
        {!props.minimal &&
            <DropUpload part_id={props.part_id} next_shot_index={props.shots.length + 1} maxFiles={10} type="add" mode='shot' />
        }
      </Grid>
    </DndContext>

  return (
    <Box sx={{ marginBottom: "2em" }}>
      <Box sx={titleStyles}>
        <Typography variant="h2" sx={{ marginBottom: "10px" }}>
          {props.title}
          {/*!expanded && <i className="angle right icon" onClick={expand}></i>}
          {expanded && <i className="angle down icon" onClick={collapse}></i>*/}
        </Typography>
        {(shots.length <= 2) ? <><Box>Describe your story with shots.</Box><Box></Box></> : <Box>{shots.length + " shots ~" + Math.floor(shots.length * 2.5) + " sec"}</Box>}
      </Box>
      {expanded && state.app.userIsStoryOwner && card_group}
      {expanded && !state.app.userIsStoryOwner && shot_group}
    </Box>
  );
}

export default RecordableShotGroup;